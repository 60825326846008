@import "the-new-css-reset/css/reset.css";
@import "./variables.scss";

* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size-base;
  color: $body-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1 {
  font-size: $font-size-xxl;
  font-weight: 900;
  margin-bottom: $med;
}

table {
  border-collapse: collapse;
}

th {
  font-weight: bold;
}

a {
  color: $action-color;

  &:hover {
    color: $actionBgColorDark;
  }
}

strong {
  font-weight: 600;
}

input,
textarea {
  padding: $sm;
  border: 0;
  background-color: $control-background-color;
  border-radius: $radius;
  font-size: 1rem;
  color: $body-color;

  &.Stealth,
  .Stealth & {
    background-color: transparent;
  }

  &:hover {
    background-color: $control-hover-background-color;
    box-shadow: $control-hover-border-shadow;
  }

  &:focus {
    background-color: $control-focus-background-color;
    box-shadow: $control-focus-border-shadow;
  }

  // Chrome, Safari, Edge, Opera
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Firefox
  &[type="number"] {
    -moz-appearance: textfield;
  }
}

label {
  font-weight: bold;
}
