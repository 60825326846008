@import "../styles/variables.scss";

.ActivityList {
  @include table-list;

  .Cell {
    &.Client {
      min-width: 180px;
    }
    &.Type {
      min-width: 162px;
    }
    &.Total {
      min-width: 80px;
      text-align: right;

      .Input {
        width: 64px;
      }
    }
    &.Details {
      min-width: 288px;
    }
  }
}

.ActivityRow {
  display: table-row;

  .TotalCol .Input {
    text-align: right;
    min-width: 88px;
  }

  .Unit {
    color: $gray-7;

    &::before {
      content: " ";
    }
  }
}
