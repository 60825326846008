$tinier: 2px;
$tiny: 4px;
$sm: 8px;
$smd: 12px;
$med: 16px;
$lg: 24px;
$xlg: 32px;
$xxlg: 48px;
$xxxlg: 64px;

$shadow-default: 0 0 1px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.1);
$shadow-card: 0 1px 1px rgba(0, 0, 0, 0.12);
$shadow-heavy: 0 0 1px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 24px rgba(0, 0, 0, 0.08);

$radius: 6px;
$radius-lg: 12px;

$gray-0_5: rgba(0, 0, 0, 0.009);
$gray-0_75: rgba(0, 0, 0, 0.02);
$gray-1: rgba(0, 0, 0, 0.05);
$gray-1_5: rgba(0, 0, 0, 0.08);
$gray-2: rgba(0, 0, 0, 0.1);
$gray-3: rgba(0, 0, 0, 0.15);
$gray-4: rgba(0, 0, 0, 0.25);
$gray-5: rgba(0, 0, 0, 0.45);
$gray-6: rgba(0, 0, 0, 0.67);
$gray-7: rgba(0, 0, 0, 0.7);
$gray-8: rgba(0, 0, 0, 0.8);
$gray-9: rgba(0, 0, 0, 0.9);

$brand1: #11ab91;
$brand1-xxxlight: #f7fcfa;
$brand1-xxlight: #f3fbf7;
$brand1-xlight: #ddf0e6;
$brand1-light: #c3e5d4;
$brand1-med: #009179;
$brand1-dark: #007363;
$brand1-darker: #00543f;

$background-color: #f9f8f7;
$body-color: #333231;
$card-stroke: $gray-1;
$frame-divider: $gray-2;
$card-background: #f2f1f0;
$logo-color: $gray-9;
$danger-color: #c44a41;

$font-size-base: 17px;
$font-size-xs: 0.76rem;
$font-size-sm: 0.82rem;
$font-size-smd: 0.88rem;
$font-size-md: 1.12rem;
$font-size-lg: 1.29rem;
$font-size-xl: 1.53rem;
$font-size-xxl: 1.88rem;

$line-height-xs: 16px;
$line-height-sm: 20px;
$line-height: 20px;
$line-height-smd: 20px;
$line-height-md: 20px;
$line-height-lg: 24px;
$line-height-xl: 32px;

$action-color: $brand1;
$actionBgColor: $action-color;
$actionBgColorDark: adjust-color($brand1, $blackness: 10%);
$disabledBgColor: $gray-1;
$disabledColor: $gray-3;

$highlight-background-color: $brand1-xxlight;

$control-background-color: rgba(255, 255, 255, 0.8);
$control-hover-background-color: white;
$control-hover-border-color: $brand1-light;
$control-hover-border-shadow: 0 0 0 1px $control-hover-border-color;

$control-focus-background-color: white;
$control-focus-border-color: $action-color;
$control-focus-border-shadow: 0 0 0 1px $control-focus-border-color;

@mixin table-list {
  width: 100%;
  margin: 0 auto;
  max-width: 1024px;
}
