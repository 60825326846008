@import "../styles/variables.scss";

nav.Nav {
  padding-left: $lg;
  padding-right: $lg;
  min-width: 240px;
  display: flex;
  flex-direction: column;
  gap: $med;

  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: $frame-divider;

  .NavItem {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: $med;
    padding: $smd $med;
    border-radius: $radius;
    border: 1px transparent solid;

    color: $body-color;
    font-weight: 600;

    svg {
      width: 24px;
      height: 24px;
    }

    &:hover {
      background-color: white;
    }

    &.Current {
      color: $brand1-med;
      background-color: white;
      border-color: $card-stroke;
    }
  }

  h1 {
    text-align: center;
    margin-bottom: 29px;

    span {
      display: none;
    }
    svg {
      width: 168px;
      color: $logo-color;
    }
  }

  .BottomBar {
    display: flex;
    flex-direction: row;
    gap: $smd;

    .Button {
      flex: 1;
      justify-content: center;

      &:hover {
        background-color: $gray-8;
        color: white;
      }
    }
  }
}
