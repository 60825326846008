@import "../styles/variables.scss";

.InvoiceView {
  display: flex;
  flex-direction: column;
  gap: $xlg;
  align-items: center;
  width: 100%;

  .Toolbar {
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $card-background;
      border: 1px $card-stroke solid;
      border-radius: 100px;
      box-shadow: $shadow-default;
      overflow: hidden;
    }
  }

  .Content {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: $xxlg;

    header {
      display: flex;
      flex-direction: row;
      gap: 96px;
      font-size: 1.25em;
      align-items: top;
      margin: 0;

      h1 {
        flex: 1;
        font-size: 2em;
        font-weight: 900;
        color: $brand1;
        margin: 0;
      }
    }

    .Parties {
      display: flex;
      flex-direction: row;
      gap: $lg;

      & > * {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: $tiny;
        white-space: pre-line;
      }

      h3 {
        font-weight: bold;
      }
    }

    table {
      border-collapse: collapse;

      th {
        white-space: nowrap;
      }

      thead th,
      .Cell.Highlight {
        background-color: $brand1-xxlight;
      }

      .Cell {
        border: 1px $gray-2 solid;
        padding: $sm;

        &.Highlight {
          font-weight: 500;
        }
      }

      .HoursCol,
      .RateCol,
      .TotalCol {
        width: 96px;
        text-align: right;
      }
    }

    .Attribution {
      display: flex;
      flex-direction: column;
      align-items: center;

      & > div {
        padding: 12px 32px;
        border-radius: $radius;
        border: 1px $gray-1 solid;
        background-color: $gray-0_75;
      }

      p {
        font-size: $font-size-sm;
        font-weight: 500;
        line-height: $line-height-sm;
        color: $gray-6;
        text-align: center;

        a {
          color: $brand1-med;
        }
      }
    }
  }
}

@media only print {
  .InvoiceView {
    .Toolbar {
      display: none;
    }
  }
}
