@import "../styles/variables.scss";

.Field {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: $tiny;

  label {
    font-weight: bold;
  }

  .StaticInput {
    color: $gray-5;
  }
}
