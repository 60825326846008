@import "../styles/variables.scss";

.ClientList {
  @include table-list;

  .Content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    max-width: 1600px;
    gap: $lg;

    & > * {
      min-height: 360px;
    }

    .AddButton {
      background-color: $gray-1;
      color: $action-color;
      border: 2px dashed $gray-2;
    }
  }
}
