@import "../styles/variables.scss";

$loc-radius: $radius-lg;
$focus-color: $action-color;

.Login {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  main {
    flex: 1;
    margin: $lg auto;
    display: flex;
    gap: $xlg * 2;
    align-items: center;

    h3 {
      font-size: 1.5rem;
      color: $gray-7;
    }

    header {
      width: 380px;
      margin-bottom: $lg;

      h1 {
        font-size: 4rem;
        margin: 0;
        color: $logo-color;
        position: relative;

        span {
          display: none;
        }

        .PrereleaseBadge {
          position: absolute;
          top: -4px;
          right: 0;
          font-size: $font-size-sm;
          line-height: $line-height-sm;
          font-weight: 700;
          color: white;
          padding: 2px 8px;
          border-radius: 4px;
          background-color: $brand1-med;
        }
      }

      h3 {
        font-weight: 500;
        font-size: 2rem;
      }

      p {
        color: $gray-5;
        line-height: 1.5em;
        font-size: $font-size-lg;
        margin-top: $lg;
      }
    }

    .LoginBox {
      min-width: 360px;
      margin-bottom: $lg;
      border-radius: $loc-radius;

      &.AwaitingVerify {
        max-width: 480px;
        min-height: 344px;
        padding: $xxlg;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h3 {
          font-weight: 700;
        }

        p {
          margin: $smd 0;
          font-size: $font-size-md;
          line-height: $line-height-lg;
          color: $gray-6;
        }
      }
    }

    .Buttons {
      text-align: center;
    }

    .TabsList {
      border-radius: $loc-radius $loc-radius 0 0;
      flex-shrink: 0;
      display: flex;
    }

    .TabsTrigger {
      padding: 0 20px;
      height: 48px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      user-select: none;
      cursor: pointer;
      box-shadow: inset 0 -1px 0 0 $gray-1, 0 1px 0 0 $gray-1;
    }
    .TabsTrigger:first-child {
      border-top-left-radius: $loc-radius;
    }
    .TabsTrigger:last-child {
      border-top-right-radius: $loc-radius;
    }
    .TabsTrigger:hover {
      background-color: $control-focus-background-color;
    }
    .TabsTrigger[data-state="active"] {
      box-shadow: inset 0 -1px 0 0 $action-color, 0 1px 0 0 $action-color;
      font-weight: 600;
      color: $actionBgColorDark;
      background-color: $brand1-xlight;
    }
    .TabsTrigger:focus {
      position: relative;
      box-shadow: $focus-color;
    }

    .TabsContent {
      flex-grow: 1;
      padding: $lg;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      outline: none;
    }
    .TabsContent:focus {
      box-shadow: 0 0 0 2px $focus-color;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: $xlg;
      align-items: center;
    }

    .Sticky {
      background-color: #ffffdd;
      box-shadow: $shadow-heavy;
      padding: $sm $med;
      border-radius: $radius;
      transform: rotate(-3deg);
      margin: -$med auto 0 auto;
      max-width: 312px;

      p {
        padding: $sm 0;
        font-weight: 500;
        line-height: 1.5em;
      }
    }
  }
}
