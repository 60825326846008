@import "../styles/variables.scss";

.InvoiceRow {
  &.Paid .Cell > * {
    opacity: 0.5;

    &.StatusInput,
    &.Select {
      opacity: 1;

      .Control {
        opacity: 0.67;
      }
    }
  }

  &.Sent .Cell {
    font-weight: 500;
  }
}
