@import "../styles/variables.scss";

.Button {
  display: flex;
  flex-direction: row;
  gap: $sm;
  align-items: center;
  justify-content: center;
  transition: opacity 0.4s, background-color 0.4s, color 0.4s;

  padding: $sm $med;
  background-color: $gray-8;
  color: white;
  font-weight: bold;
  border-radius: $radius;
  text-align: center;
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
  }

  &:hover {
    background-color: $gray-9;
  }

  &.Primary {
    background-color: $action-color;

    &:hover {
      background-color: $actionBgColorDark;
    }
  }

  &.Borderless {
    background-color: transparent;
    color: $action-color;
    padding: $sm;

    &:hover {
      background-color: $control-focus-background-color;
    }
  }

  &.Large {
    padding: $smd $med;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &:disabled {
    background-color: $disabledBgColor;
    color: $disabledColor;
  }
}
