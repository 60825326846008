@import "../styles/variables.scss";

.DateField {
  position: relative;
  min-width: 112px;

  &:hover .Button,
  &.Focused .Button {
    opacity: 1;
  }

  input {
    padding-right: 28px;
  }

  .Button {
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    padding: 6px;
    margin: 2px 0;
    transition: opacity 0.3s;
  }

  &.Focused {
    input {
      background-color: $control-focus-background-color;
      box-shadow: $control-focus-border-shadow;
    }
  }
}

.DateFieldPopoverContent {
  padding: $med;

  .rdp {
    --rdp-cell-size: 32px;
    color: $body-color;

    margin: 0;
    .rdp-caption_label {
      font-size: $font-size-base;
    }

    button {
      font-size: $font-size-smd;

      &:hover {
        background-color: $brand1-xxlight !important;
        color: $brand1-darker;
      }
    }

    .rdp-nav svg {
      width: 12px;
      height: 12px;
    }

    .rdp-day_selected,
    .rdp-day_selected:focus-visible,
    .rdp-day_selected:hover {
      background-color: $brand1;
    }

    .rdp-head_cell {
      font-size: $font-size-sm;
      color: $gray-6;
    }
  }
}

.Fieldset {
  display: flex;
  gap: 20px;
  align-items: center;
}

@import "react-day-picker/dist/style.css";
