@import "../styles/variables.scss";

$border: $card-stroke;
$cell-padding: $smd;
$cell-padding-outside: $lg;

.EditTable {
  display: table;
  width: 100%;
  max-width: 1024px;
  border-spacing: 0;
  border-collapse: separate;

  .Cell {
    &.Date {
      min-width: 108px;
    }
  }

  thead {
    .Cell {
      font-size: $font-size-smd;
      line-height: $line-height-smd;
      font-weight: 800;
      color: $gray-5;
      padding: $cell-padding;
      white-space: nowrap;

      &:first-child {
        padding-left: $cell-padding-outside;
      }
      &:last-child {
        padding-right: $cell-padding-outside;
      }
    }
  }

  tbody {
    .Cell {
      background-color: white;
      border-style: solid;
      border-width: 1px;
      border-color: transparent transparent $border transparent;
      white-space: nowrap;

      padding: $cell-padding;
      vertical-align: middle;

      .Input,
      .Select {
        margin-left: -$sm;
        margin-right: -$sm;
        width: 100%;
      }
    }

    tr {
      // Borders
      .Cell:first-child {
        border-left-color: $border;
        padding-left: $cell-padding-outside;
      }
      .Cell:last-child {
        border-right-color: $border;
        padding-right: $cell-padding-outside;
      }

      &:first-child {
        .Cell {
          border-top-color: $border;
        }

        .Cell:first-child {
          border-top-left-radius: $radius;
        }
        .Cell:last-child {
          border-top-right-radius: $radius;
        }
      }

      &:last-child {
        .Cell {
          border-bottom-color: $border;
        }

        .Cell:first-child {
          border-bottom-left-radius: $radius;
        }
        .Cell:last-child {
          border-bottom-right-radius: $radius;
        }
      }

      // States
      &.Highlighted .Cell,
      &.Focused .Cell {
        background-color: $highlight-background-color;

        input,
        .Select .Control {
          background-color: white;
          border-color: $gray-1;
        }
      }
    }
  }

  tbody {
    .BtnCol {
      width: 8px;
    }

    .Button {
      color: $gray-4;
      padding-left: $smd;
      padding-right: $smd;

      &:hover {
        color: white;
        background-color: $action-color;
      }
    }

    .Button.DrilldownButton {
      color: $action-color;

      &:hover {
        background-color: $action-color;
        color: white;
      }
    }
  }
}

.App .Button.EditTableDeleteButton:hover {
  background-color: $danger-color;
  color: white;
}
