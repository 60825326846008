@import "../styles/variables.scss";

.WeekInputs {
  display: flex;
  margin-left: 0;
  border-radius: $radius;
  box-shadow: 0 0 0 1px $gray-1_5;
  width: 240px;

  .Highlighted & {
    box-shadow: none;
  }

  &:hover {
    box-shadow: $control-hover-border-shadow;
  }

  .Input {
    margin: 0 !important;
    flex: 1;
    min-width: 0;
    text-align: center;
    border-radius: 0;
    font-size: 0.93em;

    &:first-child {
      border-top-left-radius: $radius;
      border-bottom-left-radius: $radius;
    }

    &:last-child {
      border-top-right-radius: $radius;
      border-bottom-right-radius: $radius;
    }

    &:hover {
      box-shadow: none;
      background-color: $brand1-xxlight !important;
    }

    &:focus {
      box-shadow: none;
      background-color: white !important;
    }
  }

  &.Focused {
    box-shadow: $control-focus-border-shadow;

    .Input {
      background-color: $brand1-xxxlight !important;

      &:hover {
        background-color: $brand1-xlight !important;
      }

      &:focus {
        background-color: white !important;
      }
    }
  }
}
