@import "../styles/variables.scss";

.Zero {
  max-width: 820px;
  margin: 48px auto 0 auto;
  padding: $xlg;
  border: 1px $gray-2 solid;
  background-color: $card-background;
  border-radius: $radius;

  .Image {
    text-align: center;
    opacity: 0.75;
    margin-bottom: $lg;

    svg {
      max-width: 720px;
      width: 100%;
    }
  }

  .Body {
    font-size: $font-size-lg;
    line-height: 1.33em;
    text-align: center;
    color: $gray-5;
    max-width: 708px;
    margin: auto;
  }
}
