@import "../styles/variables.scss";

.Row {
  display: flex;
  flex-direction: column;
  gap: $sm;
}

.RowContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $sm;

  &.AlignTop {
    align-items: flex-start;
  }

  &.AlignBottom {
    align-items: flex-end;
  }

  & > * {
    flex: 1;
  }
}
