@import "../styles/base.scss";
@import "../styles/variables.scss";

.App {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: $lg;

  background-color: $background-color;

  & > * {
    padding: $xlg $lg;
  }

  & > main {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    & > * {
      flex: 1;
    }

    & > footer {
      flex: 0;
    }
  }

  &.Loading > main {
    align-items: center;
    font-size: 125%;

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.PopoverTrigger {
  background-color: white;
  border: 0;
  border-radius: 4px;
}

.PopoverContent {
  background-color: white;
  border-radius: $radius;
  box-shadow: $shadow-heavy;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}
.PopoverContent:focus {
  box-shadow: $shadow-heavy, $control-focus-border-shadow;
}
.PopoverContent[data-state="open"][data-side="top"] {
  animation-name: slideDownAndFade;
}
.PopoverContent[data-state="open"][data-side="right"] {
  animation-name: slideLeftAndFade;
}
.PopoverContent[data-state="open"][data-side="bottom"] {
  animation-name: slideUpAndFade;
}
.PopoverContent[data-state="open"][data-side="left"] {
  animation-name: slideRightAndFade;
}
@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.PopoverArrow {
  fill: #eee;
}

::placeholder {
  color: $gray-3;
}

.Error {
  font-weight: 500;
  color: $danger-color;
}

.Screen {
  header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 $lg 0;

    h1 {
      flex: 1;
      margin: 0;
    }
  }
}

.Card {
  background-color: $card-background;
  border-radius: $radius;
  border: 1px $gray-1 solid;
}

.SimpleForm,
.SimpleForm section {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: $med;

  &.Sectioned {
    background-color: $card-stroke;
    gap: 1px;
  }

  section {
    padding: $lg;
    background-color: $card-background;
  }
}

@media only print {
  html {
    margin: 0.5in;
  }

  .App {
    position: static !important;
    background-color: white;
  }

  nav {
    display: none !important;
  }

  main {
    padding: 0 !important;

    footer {
      display: none;
    }
  }

  .Screen {
    max-width: none !important;
  }
}

$tooltip-background: adjust-color($gray-8, $alpha: -0.25);

.Tooltip {
  border-radius: $radius;
  padding: $smd $med;
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  background-color: $tooltip-background;
  color: white;
  font-weight: 500;
  backdrop-filter: blur(10px);
  box-shadow: $shadow-heavy;
  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  max-width: 304px;
  z-index: 9999;
}
.Tooltip[data-state="delayed-open"][data-side="top"] {
  animation-name: slideDownAndFade;
}
.Tooltip[data-state="delayed-open"][data-side="right"] {
  animation-name: slideLeftAndFade;
}
.Tooltip[data-state="delayed-open"][data-side="bottom"] {
  animation-name: slideUpAndFade;
}
.Tooltip[data-state="delayed-open"][data-side="left"] {
  animation-name: slideRightAndFade;
}

.TooltipArrow {
  fill: $tooltip-background;
}
