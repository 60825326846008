@import "../styles/variables.scss";

$_padding: $med;

.ClientView {
  overflow: hidden;

  section {
    padding: $_padding;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: $gray-0_5;
    gap: $lg;
  }

  .Title input {
    font-size: $font-size-md;
    font-weight: bold;
  }

  .EditTableDeleteButton {
    flex: 0;
    color: $gray-5;
    padding-left: $smd;
    padding-right: $smd;
    align-self: stretch;
  }

  label {
    color: $gray-6;
    font-size: $font-size-sm;
  }

  .Tag {
    font-weight: 900;
    font-size: $font-size-xs;
    color: $gray-3;
    text-transform: uppercase;
  }

  .Hint {
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    color: $gray-5;
  }
}
