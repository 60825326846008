@import "../styles/variables.scss";

.InvoiceList {
  @include table-list;

  tbody,
  thead {
    .Id {
      min-width: 72px;
    }

    .Date {
      width: 100px;
    }
    .Items {
      width: 32px;
      text-align: right;
    }
    .Status {
      min-width: 116px;
    }
    .Amount {
      text-align: right;
    }
  }
}
