@import "../styles/variables.scss";

div.Select {
  .Control,
  &.Borderless .Control {
    border: 0;
    background-color: $control-background-color;

    &:hover {
      background-color: $control-hover-background-color;
      box-shadow: $control-hover-border-shadow;

      .Dropdown {
        opacity: 1;
      }
    }

    &.Focused {
      box-shadow: $control-focus-border-shadow;
      background-color: $control-focus-background-color;

      .Dropdown {
        opacity: 1;
      }
    }
  }

  .Dropdown {
    padding: 0 $sm;
    display: flex;

    svg {
      color: $gray-6;
    }
  }

  &.Borderless {
    .Control {
      padding: 8px 4px;
      min-width: 80px;
      min-height: auto;
      border-radius: $radius;
      background-color: transparent;
    }

    .ValueContainer,
    .InputContainer {
      margin: 0;
      padding: 0;
    }

    .SelectMenu {
      margin-top: 4px;

      .Option {
        &.Focused {
          background-color: $brand1-xlight;
        }
        &.Selected {
          background-color: $action-color;
        }
      }
    }

    .Dropdown {
      opacity: 0;
    }
  }

  .Focused & .Control .Dropdown,
  .Highlighted & .Control .Dropdown {
    opacity: 0.5;
  }
}
